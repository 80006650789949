<template>
  <Modal @reset_modal="$emit('reset_modal')">
    <div class="ytm-default-text" style="font-size: 1.75rem; font-weight: 600; text-align: center; margin-bottom: 1.75rem">
      Приглашение на курс
    </div>
    <p class="ytm-default-text" style="text-align: center">
      Вы попали на эту страницу по invite-ссылке. Вы готовы присоединиться
      к&nbspкурсу&nbsp<b>{{linkData.title}}</b> в&nbspроли&nbsp<b>{{getRole(linkData.roleId)}}</b>?
    </p>
    <p v-if="errorMsg" class="ytm-default-text" style="margin-top: 1rem; margin-bottom: -1rem; text-align: center; color: red">
      {{errorMsg}}
    </p>
    <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
      <div
          class="ytm-blue-button ytm-default-text"
          style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
          @click="joinCourse"
      >
        Присоединиться!
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/redesign/Modal";
import axios from "axios";
import {SERVICE_MAIN_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";

export default {
  name: 'InvitationModal',
  components: {Modal},
  props: {
    linkData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      errorMsg: '',
    };
  },
  methods: {
    joinCourse() {
      this.$store.dispatch('auth/autoLogin').then(() => {
        axios.post(
            SERVICE_MAIN_URI + '/joinViaInviteLink',
            {inviteLink: this.$route.query.join},
            {headers: authHeader()}
        ).then(resp => {
          this.$router.push('/course/' + this.linkData.shortLink);
        }).catch(err => {
          if (err.response.data.message.startsWith('students number error')) {
            this.errorMsg = 'К сожалению, на курсе достигнут лимит количества учеников'
          } else {
            this.errorMsg = 'Произошла ошибка, попробуйте ещё раз';
          }
        });
      });
    },
    getRole(role) {
      if (role === 1) {
        return 'преподавателя';
      } else if (role === 2) {
        return 'ученика';
      } else {
        return '';
      }
    },
  },
};
</script>

<style scoped>

</style>