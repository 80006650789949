<template>
    <Modal @reset_modal="$emit('reset_modal')">
        <div class="ytm-default-text" style="font-size: 1.75rem; font-weight: 600; text-align: center; margin-bottom: 1.75rem">
            Создать новый курс
        </div>
        <div v-if="tariffId === -1">
            <p class="ytm-default-text" style="text-align: center; font-size: 1.25rem">
                Чтобы создать курс, нужно выбрать тариф
            </p>
            <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
                <div
                        class="ytm-blue-button ytm-default-text"
                        style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
                        @click="$router.push('/account?section=tariff')"
                >
                    К выбору тарифа
                </div>
            </div>
        </div>
        <div v-else>
            <DefaultTip style="margin-bottom: 1.25rem">
                <p class="ytm-default-text" style="font-weight: 600; line-height: 130%; color: #FFFFFF; font-size: 0.9rem; margin-bottom: 0.45rem; max-width: 700px">
                    Курс&nbsp– это единое пространство, в&nbspкотором вы будете работать с&nbspучеником или&nbspгруппой. К&nbspкурсу можно прикреплять&nbspуроки, приглашать&nbspучеников и общаться&nbspс&nbspними в&nbspчате.
                </p>
                <a class="ytm-default-text" style="font-weight: 700; color: #FFFFFF; font-size: 0.9rem" href="/tutorial" target="_blank" rel="noopener noreferer">
                    Узнать больше
                    <svg style="position: relative; top: 0.2rem; width: 0.9rem; height: 1rem" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 13.3679C0.895431 13.3679 8.19842e-08 14.2634 0 15.3679C-8.19842e-08 16.4725 0.89543 17.3679 2 17.3679L2 13.3679ZM33.1296 16.7821C33.9106 16.0011 33.9106 14.7348 33.1296 13.9537L20.4016 1.22579C19.6206 0.444737 18.3543 0.444737 17.5732 1.22579C16.7922 2.00683 16.7922 3.27316 17.5732 4.05421L28.8869 15.3679L17.5732 26.6816C16.7922 27.4627 16.7922 28.729 17.5732 29.5101C18.3543 30.2911 19.6206 30.2911 20.4016 29.5101L33.1296 16.7821ZM2 17.3679L31.7153 17.3679L31.7153 13.3679L2 13.3679L2 17.3679Z" fill="white"/>
                    </svg>
                </a>
            </DefaultTip>
            <InputGroup class="ytm-modal-input" v-model="formData.title" title="Название" placeholder="Название курса" :focus="true" @keyup.enter="createCourse"/>
            <InputGroup class="ytm-modal-input" v-model="formData.costPerHour" type="number" title="Стоимость часа" placeholder="Стоимость часа занятий" @input="onCostInput" @keyup.enter="createCourse"/>
            <SelectGroup title="Тип курса" :options="options" v-model="isIndividual"/>
            <p v-if="errorMsg" class="ytm-default-text" style="margin-top: 1rem; margin-bottom: -1rem; text-align: center; color: red">
                {{errorMsg}}
            </p>
            <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
                <div
                        class="ytm-blue-button ytm-default-text"
                        style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
                        @click="createCourse"
                >
                    Создать курс
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from "@/components/redesign/Modal";
import InputGroup from "@/components/redesign/Form/InputGroup";
import SelectGroup from "@/components/redesign/Form/SelectGroup";
import axios from "axios";
import {SERVICE_MAIN_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";
import DefaultTip from "@/components/redesign/Tips/DefaultTip.vue";

export default {
    name: 'NewCourseModal',
    components: {DefaultTip, Modal, InputGroup, SelectGroup},
    data() {
        return {
            formData: {
                title: '',
                costPerHour: 0,
            },
            isIndividual: '1',
            creating: false,
            errorMsg: '',
        };
    },
    computed: {
        tariffId() {
            const userInfo = this.$store.getters['user_info/user_info'];
            if (userInfo && userInfo.tariffState && userInfo.tariffState.tariff) {
                return userInfo.tariffState.tariff.id;
            } else {
                return -1;
            }
        },
        options() {
            let options = [{
                value: '1',
                desc: 'Индивидуальный',
            }];
            if (this.tariffId === 1 || Math.floor(this.tariffId / 100) === 3) {
                options.push({
                    value: '0',
                    desc: 'Групповой',
                });
            }
            return options;
        },
    },
    methods: {
        onCostInput() {
            this.formData.costPerHour = Math.max(0, Math.round(this.formData.costPerHour));
        },
        createCourse() {
            if (this.creating) {
                return;
            }
            this.formData.title = this.formData.title.trim();
            if (this.formData.title.length === 0) {
                this.errorMsg = 'Заполните, пожалуйста, название';
                return;
            }
            this.errorMsg = '';
            this.creating = true;
            let payload = {
                title: this.formData.title,
                costPerHour: Math.max(0, Math.round(this.formData.costPerHour)),
                isIndividual: (this.isIndividual !== '0' ? 1 : 0),
            };
            this.$store.dispatch('auth/autoLogin').then(() => {
                axios.post(
                    SERVICE_MAIN_URI + '/course',
                    payload,
                    {headers: authHeader()},
                ).then(() => {
                    this.$emit('course_created');
                    this.creating = false;
                    this.$emit('reset_modal');
                }).catch(err => {
                    if (err.response && err.response.data.message && err.response.data.message.startsWith('tariffError:')) {
                        this.errorMsg = err.response.data.message.slice('tariffError: '.length);
                    } else {
                        this.errorMsg = 'Упс, не удалось создать курс';
                        console.error(err);
                    }
                    this.creating = false;
                });
            });
        },
    },
};
</script>

<style scoped>
.ytm-modal-input {
    margin-bottom: 1.25rem;
}
</style>