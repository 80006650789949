<template>
    <div class="ytm-container">
        <NewCourseModal v-if="showNewCourseModal" @reset_modal="showNewCourseModal = false" @course_created="getCourses"/>
        <InvitationModal :linkData="linkData" v-if="showInvitationModal" @reset_modal="showInvitationModal = false"/>
        <Navbar/>
        <p class="ytm-default-text ytm-head-text" style="margin-top: 2.5rem; margin-bottom: 1.5rem">
            Мои курсы
        </p>
        <div v-if="loading">
            <div style="display: flex; margin-bottom: 2.5rem">
                <div class="skeleton" style="height: 2.5rem; width: 10rem; border-radius: 1.5rem; margin-right: 1rem"/>
                <div class="skeleton" style="height: 2.5rem; width: 10rem; border-radius: 1.5rem"/>
            </div>
            <div class="ytm-courses-wrapper">
                <div class="skeleton ytm-course-card-skeleton"/>
                <div class="skeleton ytm-course-card-skeleton"/>
                <div class="skeleton ytm-course-card-skeleton"/>
            </div>
        </div>
        <div v-else-if="failed" class="ytm-default-text ytm-onboarding-text" style="margin-top: 10vh">
            Ошибка загрузки :(
        </div>
        <div v-else-if="!isTeacher" style="margin-top: 3rem">
            <div v-if="courses && courses.asStudent && courses.asStudent.length > 0">
                <div class="ytm-courses-wrapper">
                    <CourseCard v-for="course in courses.asStudent" :key="course.shortLink" :course="course" role="student"/>
                </div>
            </div>
            <div v-else class="ytm-default-text ytm-onboarding-text" style="margin-top: 10vh">
                Пока нет курсов
                <div style="margin: 1.5rem 0; width: 100%; display: flex; justify-content: center">
                    <div
                            class="ytm-blue-button ytm-default-text"
                            style="color: #FFFFFF; font-size: 1.25rem; font-weight: 600"
                            @click="$router.push('/account')"
                    >
                        Стать преподавателем
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div style="display: flex; gap: 1rem; margin-bottom: 2.5rem">
                <div style="flex-grow: 1">
                    <div class="ytm-tabs-titles">
                        <div
                                :class="{'ytm-tab ytm-default-text ytm-tab-title': true, 'ytm-tab-selected': showTeacherCourses}"
                                @click="showTeacherCourses = true"
                        >
                            Я преподаватель
                        </div>
                        <div
                                :class="{'ytm-tab ytm-default-text ytm-tab-title': true, 'ytm-tab-selected': !showTeacherCourses}"
                                @click="showTeacherCourses = false"
                        >
                            Я ученик
                        </div>
                    </div>
                </div>
                <div>
                    <div class="ytm-black-button" style="display: table" @click="showNewCourseModal = true">
                        <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" style="height: 1.2rem; display: table-cell; vertical-align: middle; margin-right: 0.8rem">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 3C20.5 1.61929 19.3807 0.5 18 0.5C16.6193 0.5 15.5 1.61929 15.5 3V14.5H3C1.61929 14.5 0.5 15.6193 0.5 17C0.5 18.3807 1.61929 19.5 3 19.5H15.5V32C15.5 33.3807 16.6193 34.5 18 34.5C19.3807 34.5 20.5 33.3807 20.5 32V19.5H32C33.3807 19.5 34.5 18.3807 34.5 17C34.5 15.6193 33.3807 14.5 32 14.5H20.5V3Z" fill="white"/>
                        </svg>
                        <span class="ytm-default-text ytm-create-course" style="font-weight: 600; color: #FFFFFF; display: table-cell; vertical-align: middle">
              Создать курс
            </span>
                    </div>
                </div>
            </div>
            <div v-show="showTeacherCourses">
                <div v-if="courses && courses.asTeacher && courses.asTeacher.length > 0">
                    <div class="ytm-courses-wrapper">
                        <CourseCard v-for="course in courses.asTeacher" :key="course.shortLink" :course="course" role="teacher"/>
                    </div>
                </div>
                <div v-else class="ytm-default-text ytm-onboarding-text" style="margin-top: 10vh">
                    Пока нет курсов в роли преподавателя
                </div>
            </div>
            <div v-show="!showTeacherCourses">
                <div v-if="courses && courses.asStudent && courses.asStudent.length > 0">
                    <div class="ytm-courses-wrapper">
                        <CourseCard v-for="course in courses.asStudent" :key="course.shortLink" :course="course" role="student"/>
                    </div>
                </div>
                <div v-else class="ytm-default-text ytm-onboarding-text" style="margin-top: 10vh">
                    Пока нет курсов в роли ученика
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "@/styles/style.css";
import "@/components/CoursePageV2/skeletons.css";
import Navbar from "@/components/redesign/Navbar.vue";
import axios from "axios";
import {SERVICE_MAIN_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";
import CourseCard from "@/components/CoursesPage/CourseCard.vue";
import NewCourseModal from "@/components/CoursesPage/modals/NewCourseModal.vue";
import InvitationModal from "@/components/CoursesPage/modals/InvitationModal.vue";

export default {
    name: 'CoursesPage',
    components: {InvitationModal, NewCourseModal, Navbar, CourseCard},
    data() {
        return {
            showTeacherCourses: true,
            showNewCourseModal: false,
            showInvitationModal: false,
            linkData: null,
        };
    },
    computed: {
        loading() {
            return this.$store.getters['user_info/loading'] || this.$store.getters['courses/loading'];
        },
        failed() {
            return this.$store.getters['user_info/failed'] || this.$store.getters['courses/failed'];
        },
        courses() {
            return this.$store.getters['courses/courses'];
        },
        isTeacher() {
            const info = this.$store.getters['user_info/user_info'];
            return info && info.teacherState && info.teacherState.isT;
        },
    },
    methods: {
        getCourses() {
            this.$store.dispatch('courses/fetchCourses').catch(err => {
                console.error(err);
            });
        },
        handleInviteLink() {
            if (!this.$route.query.join) {
                return;
            }
            const link = this.$route.query.join;
            this.$store.dispatch('auth/autoLogin').then(() => {
                axios.post(
                    SERVICE_MAIN_URI + '/getInviteLinkData',
                    {
                        inviteLink: link,
                    },
                    {headers: authHeader()},
                ).then(resp => {
                    this.linkData = resp.data.out;
                    if (this.linkData.alreadyIn) {
                        this.$router.push('/course/' + this.linkData.shortLink);
                    } else {
                        this.showInvitationModal = true;
                    }
                }).catch(err => {
                    this.linkData = null;
                    this.showInvitationModal = true;
                });
            });
        },
    },
    mounted() {
        this.getCourses();
        this.$store.dispatch('user_info/fetchUserInfo').catch(err => {
            console.error(err);
        });
        this.handleInviteLink();
    },
};
</script>

<style scoped>
.ytm-head-text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 116.6%;
    color: #000000;
}
.ytm-create-course {
    white-space: nowrap;
}
.ytm-courses-wrapper {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    margin-bottom: 2rem;
}
.ytm-course-card-skeleton {
    margin-right: 2rem;
    margin-bottom: 2rem;
    height: 15rem;
    border-radius: 2.5rem;
}
</style>